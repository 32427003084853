import { useConfig } from '@lib/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

const FreshDesk = ({ isStudyAdmin }) => {
  const router = useRouter();
  const { freshDesk, freshDeskAdmin } = useConfig();
  const widgetId = isStudyAdmin ? freshDeskAdmin : freshDesk;

  useEffect(() => {
    window.fwSettings = {};
    window.fwSettings = {
      widget_id: widgetId,
    };

    if (typeof window.FreshworksWidget !== 'function') {
      window.FreshworksWidget('destroy');
      window.FreshworksWidget = function () {
        window.FreshworksWidget.q.push(arguments);
      };
      window.FreshworksWidget.q = [];
    }
  }, [router.pathname, isStudyAdmin, widgetId]);

  return (
    <>
      <Script
        src={`https://widget.freshworks.com/widgets/${widgetId}.js`}
        strategy="afterInteractive"
      />

      <Script id="freshdesk-initialization" strategy="afterInteractive">
        {`!function(){if("function"!=typeof window.FreshworksWidget)
        {var n=function(){n.q.push(arguments)};
        n.q=[],window.FreshworksWidget=n}}()`}
      </Script>
    </>
  );
};

export default FreshDesk;
