import { isEmpty } from '@lib/fp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  alpha,
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'src/components/LinkNext';

/**
 * @param {object} props
 * @param {[object]} props.studies
 * @param {string} props.feature
 * @param {string} props.studyId
 * @param {boolean} props.isExpanded
 * @param {Function} props.dropdownDidToggle
 */
const StudyDropdown = ({
  studies,
  feature,
  studyId,
  isExpanded,
  dropdownDidToggle,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    dropdownDidToggle(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    dropdownDidToggle(false);
  };

  const activeStudy = studies.find(x => x.id === studyId);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ButtonBase
        data-testid="menu-study-dropdown"
        onClick={handlePopoverOpen}
        sx={{
          width: '100%',
          color: 'white',
          flexDirection: 'column',
          alignItems: 'flex-start',
          px: 1,
          borderRadius: 1,
          '&:hover': {
            background: theme => alpha(theme.palette.common.white, 0.08),
          },
        }}
      >
        <Typography variant="overline" sx={{ lineHeight: 2 }}>
          {intl.formatMessage({ defaultMessage: 'Study' })}
        </Typography>

        <StudyNameLabel activeStudy={activeStudy} isExpanded={isExpanded} />
      </ButtonBase>

      <Popover
        id={id}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { minWidth: '208px' },
        }}
      >
        <List>
          {!isEmpty(studies) ? (
            studies.map(study => (
              <ListItem
                button
                key={study.id}
                onClick={handlePopoverClose}
                href={navigationStudyPath(study, feature)}
                component={Link}
                disableGutters
              >
                <ListItemText
                  primary={
                    <Box px={1}>
                      <Typography variant="body2">
                        {study.internalStudyName}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))
          ) : (
            <ListItem button disabled disableGutters>
              <ListItemText
                primary={intl.formatMessage({
                  defaultMessage: 'No other studies',
                })}
              />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

const StudyNameLabel = ({ activeStudy, isExpanded }) => {
  const intl = useIntl();

  const studyDisplayName =
    activeStudy?.internalStudyName ??
    intl.formatMessage({ defaultMessage: 'Select Study' });

  return (
    <Box display="flex" height={24}>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          maxWidth: 204,
          opacity: isExpanded ? 1 : 0,
          transition: expandedStudyNameLabelTransition(isExpanded, true),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {studyDisplayName}
        </Typography>
        <Box ml={0.5}>
          <ArrowDropDownIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          maxWidth: 48,
          opacity: isExpanded ? 0 : 1,
          transition: expandedStudyNameLabelTransition(isExpanded, false),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {studyDisplayName}
        </Typography>
      </Box>
    </Box>
  );
};

const expandedStudyNameLabelTransition = (isExpanding, expandedComponent) => ({
  transitions,
}) => {
  let delay;
  if (expandedComponent) {
    delay = isExpanding ? transitions.duration.enteringScreen : 0;
  } else {
    delay = isExpanding ? 0 : transitions.duration.leavingScreen;
  }

  return transitions.create('opacity', {
    easing: transitions.easing.sharp,
    duration: isExpanding
      ? transitions.duration.enteringScreen
      : transitions.duration.leavingScreen,
    delay,
  });
};

const navigationStudyPath = ({ id, enabledFeature }, feature) => {
  return `/studies/${id}/${feature ? feature : enabledFeature}`;
};

export default StudyDropdown;
