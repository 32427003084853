import MuiArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiCheckIcon from '@mui/icons-material/Check';
import MuiClearIcon from '@mui/icons-material/Clear';
import MuiSearchIcon from '@mui/icons-material/Search';
import {
  alpha,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { difference, isSubset, union } from '../../fp';
import { createOptionsAndDefault } from './createOptionsAndDefault';

const FilterChip = ({
  label,
  defaultValue = [],
  onChange,
  options = [],
  disabled = false,
  multiple = true,
  groupBy = null,
  otherGroupName = null,
  maxWidth = '16ch',
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('');

  const {
    allValues,
    cleanedDefaultValues,
    filteredOptions,
    groups,
    otherGroup,
  } = useMemo(
    () => createOptionsAndDefault(options, defaultValue, filter, groupBy),
    [defaultValue, filter, groupBy, options],
  );
  const [value, setValue] = useState(cleanedDefaultValues);

  const handleChange = changeValues =>
    setValue(currentValues => {
      const updatedValues = isSubset(changeValues, currentValues)
        ? difference(currentValues, changeValues)
        : multiple
        ? union(currentValues, changeValues)
        : changeValues;

      onChange?.(updatedValues);

      return updatedValues;
    });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const isDisabled = disabled || options.length <= 1;
  const allSelected = value.length === options.length;
  const isEmpty = value.length === 0;
  const isGrouped = multiple && groupBy instanceof Function;

  const IconComponent = isEmpty ? ArrowDropDownIcon : CheckIcon;
  const open = Boolean(anchorEl);
  const id = open ? 'a-popper-label' : undefined;

  return (
    <>
      <ButtonBase
        sx={{
          pr: 1,
          pl: 2,
          fontSize: '16px',
          height: '32px',
          borderRadius: 16,
          ...selectStyles(isEmpty, isDisabled),
        }}
        onClick={handleClick}
        disabled={isDisabled}
        name={label}
      >
        <Box mr={1} maxWidth={maxWidth}>
          <ChipValue value={value} options={options} label={label} />
        </Box>
        <IconComponent disabled={isDisabled} />
      </ButtonBase>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
            },
          },
        ]}
        sx={{
          border: '1px solid rgba(27,31,35,.15)',
          borderRadius: 2,
          boxShadow: 7,
          width: 300,
          zIndex: 9999,
          fontSize: 13,
          backgroundColor: 'white',
          overflowY: 'auto',
          maxHeight: '75%',
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            {options.length >= 8 && (
              <Box m={2} mb={1}>
                <FilterChipSearchBox
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                />
              </Box>
            )}
            <List aria-labelledby="filter-chip-heading">
              {options.length >= 8 && !filter.length && <Divider />}
              {multiple && !filter.length && (
                <>
                  <OptionItem
                    value={'all'}
                    label={'All'}
                    selected={allSelected}
                    onClick={() => handleChange(allValues)}
                  />
                  <Divider />
                </>
              )}
              {filteredOptions.length === 0 && (
                <Typography variant="body2" align="center" mb={1}>
                  {intl.formatMessage({ defaultMessage: 'No search results' })}
                </Typography>
              )}
              {isGrouped &&
              Object.keys(groups).length > (otherGroup ? 0 : 1) ? (
                <>
                  {Object.keys(groups)
                    .sort()
                    .map((key, index) => {
                      const group = groups[key];
                      return (
                        <Fragment key={`${key}-group`}>
                          <ListGroup
                            group={group}
                            groupName={key}
                            currentValues={value}
                            handleChange={handleChange}
                          />
                          {index < Object.keys(groups).length - 1 && (
                            <Divider />
                          )}
                        </Fragment>
                      );
                    })}
                  {otherGroup && (
                    <>
                      <Divider />
                      <ListGroup
                        group={otherGroup}
                        groupName={
                          otherGroupName ??
                          intl.formatMessage({ defaultMessage: 'Other' })
                        }
                        currentValues={value}
                        handleChange={handleChange}
                      />
                    </>
                  )}
                </>
              ) : (
                filteredOptions.map(o => (
                  <OptionItem
                    key={o.value}
                    value={o.value}
                    label={o.label}
                    subLabel={o.subLabel}
                    selected={value.includes(o.value)}
                    onClick={() => handleChange([o.value])}
                  />
                ))
              )}
            </List>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const ListGroup = ({ group, groupName, currentValues, handleChange }) => {
  const groupValues = group.map(x => x.value);
  return (
    <>
      <GroupHeader
        value={groupName}
        selected={isSubset(groupValues, currentValues)}
        onClick={() => handleChange(groupValues)}
      />
      {group.map(o => (
        <OptionItem
          key={o.value}
          value={o.value}
          label={o.label}
          subLabel={o.subLabel}
          selected={currentValues.includes(o.value)}
          onClick={() => handleChange([o.value])}
        />
      ))}
    </>
  );
};

const OptionItem = ({ label, value, selected, onClick, subLabel }) => (
  <ListItem
    button
    onClick={onClick}
    aria-selected={selected}
    data-testid={`filter-chip-${value.toLowerCase()}`}
  >
    <ListCheckIcon visibility={selected ? 'visible' : 'hidden'} />
    <ListItemText
      primary={label}
      secondary={subLabel}
      primaryTypographyProps={{ variant: 'body2', noWrap: true }}
      secondaryTypographyProps={{ noWrap: true }}
    />
  </ListItem>
);

const GroupHeader = ({ value, selected, onClick }) => (
  <ListItem
    button
    onClick={onClick}
    aria-selected={selected}
    data-testid={`filter-chip-header-${value.toLowerCase()}`}
  >
    <ListCheckIcon visibility={selected ? 'visible' : 'hidden'} />
    <ListItemText
      primary={value}
      primaryTypographyProps={{ variant: 'subtitle2', noWrap: true }}
    />
  </ListItem>
);

const selectStyles = (isEmpty, disabled) => {
  if (disabled) {
    return {
      backgroundColor: ({ palette }) =>
        isEmpty ? 'grey.200' : alpha(palette.secondary.main, 0.5),
      color: isEmpty ? 'text.disabled' : 'white',
    };
  } else if (isEmpty) {
    return {
      backgroundColor: 'grey.200',
      color: 'text.primary',
      boxShadow: ({ palette }) => `inset 0 0 0 1px ${palette.grey[300]}`,
      '&:hover': {
        backgroundColor: 'grey.300',
        boxShadow: 'none',
      },
    };
  } else {
    return {
      color: 'white',
      backgroundColor: 'secondary.main',
      '&:hover': {
        backgroundColor: 'secondary.dark',
      },
    };
  }
};

const FilterChipSearchBox = ({ value, onChange }) => {
  const intl = useIntl();

  return (
    <TextField
      fullWidth
      value={value}
      onChange={onChange}
      placeholder={intl.formatMessage({ defaultMessage: 'Search' })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MuiSearchIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search field"
              onClick={() => onChange({ target: { value: '' } })}
              edge="end"
              size="small"
            >
              <MuiClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

const ArrowDropDownIcon = props => (
  <MuiArrowDropDownIcon
    sx={{ color: props.disabled ? 'inherit' : 'grey.800' }}
  />
);

const CheckIcon = props => (
  <MuiCheckIcon {...props} sx={{ color: 'inherit' }} />
);

const ListCheckIcon = ({ visibility }) => (
  <MuiCheckIcon
    sx={{
      color: 'secondary.main',
      mr: 1,
      visibility,
    }}
  />
);

const ChipValue = ({ value, options, label }) => {
  if (!value || value.length === 0) {
    return <Typography noWrap>{label}</Typography>;
  }

  const valueLabel = options.find(x => x.value === value[0])?.label ?? '';

  if (value.length === 1) {
    return <Typography noWrap>{valueLabel}</Typography>;
  }

  return (
    <Stack direction="row" spacing={0.5}>
      <Typography noWrap>{valueLabel}</Typography>
      <Typography sx={{ whiteSpace: 'nowrap' }}>{`+ ${
        value.length - 1
      }`}</Typography>
    </Stack>
  );
};

export default FilterChip;
