import { groupBy as _groupBy } from '../../fp';

export const createOptionsAndDefault = (
  options,
  defaultValue = [],
  filter,
  groupBy,
) => {
  const allValues = options.map(x => x.value);
  const cleanedDefaultValues = defaultValue.filter(v => allValues.includes(v));

  const filteredOptions = options.filter(
    x => !filter || x.label.toLowerCase().includes(filter.toLowerCase()),
  );
  const { undefined: otherGroup, ...groups } = _groupBy(
    filteredOptions,
    groupBy,
  );

  return {
    allValues,
    cleanedDefaultValues,
    filteredOptions,
    groups,
    otherGroup,
  };
};
