import {
  alpha,
  Box,
  Grow,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Link from 'src/components/LinkNext';

/**
 * @param {object} props
 * @param {object} props.title
 * @param {object} props.icon
 * @param {boolean} props.active
 * @param {string} props.path
 * @param {string} props.userOnboardingId
 * @param {boolean} props.expanded
 */
function SubNavigation({
  title = '',
  icon,
  active = false,
  path,
  userOnboardingId,
  expanded,
}) {
  const listItemProps = !active
    ? {
        component: Link,
        href: path,
        underline: 'none',
      }
    : {};

  const getTextColor = ({ palette }) =>
    active ? palette.primary.dark : alpha(palette.common.white, 0.75);
  const getIconColor = ({ palette }) =>
    active ? palette.secondary.main : alpha(palette.common.white, 0.75);

  return (
    <Box my={3}>
      <ListItemButton
        sx={{
          color: getTextColor,
          bgcolor: active ? 'white !important' : 'transparent',
          height: 40,
          borderRadius: 1,
          userSelect: 'none',
          '&:hover': {
            background: ({ palette }) => alpha(palette.common.white, 0.08),
          },
        }}
        selected={active}
        disableGutters
        data-testid={userOnboardingId}
        {...listItemProps}
      >
        <ListItemIcon
          sx={{
            color: getIconColor,
            minWidth: '40px',
            marginRight: 1,
            justifyContent: 'center',
            fontSize: '10px',
          }}
        >
          {icon}
        </ListItemIcon>
        <Grow
          in={expanded}
          style={{ transformOrigin: 'left center' }}
          {...(expanded ? { timeout: 700 } : {})}
        >
          <ListItemText
            primary={
              <Typography variant="subtitle1" noWrap>
                {title}
              </Typography>
            }
          />
        </Grow>
      </ListItemButton>
    </Box>
  );
}

export default SubNavigation;
