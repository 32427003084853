import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { FormattedMessage } from 'react-intl';
import {
  ANALYTICS_FEATURE,
  CONSENTEE_FEATURE,
  RECRUITMENT_FEATURE,
} from 'src/constants';

export const getFeatureAttributes = feature => {
  return (
    featureMap[feature] ?? {
      color: 'black',
      Icon: () => null,
      label: <FormattedMessage defaultMessage="Unknown feature" />,
    }
  );
};

const featureMap = {
  [ANALYTICS_FEATURE]: {
    key: ANALYTICS_FEATURE,
    Icon: DashboardIcon,
    name: <FormattedMessage defaultMessage="analytics" />,
  },
  [RECRUITMENT_FEATURE]: {
    key: RECRUITMENT_FEATURE,
    Icon: PermContactCalendarIcon,
    name: <FormattedMessage defaultMessage="recruitment" />,
  },
  [CONSENTEE_FEATURE]: {
    key: CONSENTEE_FEATURE,
    Icon: HistoryEduIcon,
    name: <FormattedMessage defaultMessage="consent" />,
  },
};
