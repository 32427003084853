import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import { FormattedMessage } from 'react-intl';
import {
  ANALYTICS_FEATURE,
  CONSENTEE_FEATURE,
  RECRUITMENT_FEATURE,
} from 'src/constants';

const accessRequirements = checkAccess => {
  if (checkAccess({ features: [RECRUITMENT_FEATURE] })) {
    if (
      checkAccess({
        actions: ['analytics.candidates:get'],
        features: [ANALYTICS_FEATURE],
      })
    ) {
      return true;
    }

    if (
      checkAccess({
        actions: ['analytics:show'],
      })
    ) {
      return true;
    }
  }
  return false;
};

/**
 * @dev use this list to extend the sidebar layout design
 */
const menuItems = [
  {
    key: ANALYTICS_FEATURE,
    icon: <DashboardIcon color="inherit" />,
    title: <FormattedMessage defaultMessage="Dashboard" />,
    path: `/studies/:studyId/${ANALYTICS_FEATURE}`,
    userOnboardingId: 'menu-dashboard',
    accessRequirements: checkAccess => accessRequirements(checkAccess),
    mixPanelName: 'Menu link clicked analytics',
  },
  {
    key: RECRUITMENT_FEATURE,
    icon: <PermContactCalendarIcon color="inherit" />,
    title: <FormattedMessage defaultMessage="Candidates" />,
    path: `/studies/:studyId/${RECRUITMENT_FEATURE}`,
    userOnboardingId: 'menu-candidates',
    accessRequirements: checkAccess =>
      checkAccess({
        actions: ['candidate:list'],
        features: [RECRUITMENT_FEATURE],
      }),
    mixPanelName: 'Menu link clicked candidates',
  },
  {
    key: CONSENTEE_FEATURE,
    icon: <HistoryEduIcon color="inherit" />,
    title: <FormattedMessage defaultMessage="Consentees" />,
    path: `/studies/:studyId/${CONSENTEE_FEATURE}`,
    userOnboardingId: 'menu-consentees',
    accessRequirements: checkAccess =>
      checkAccess({
        actions: ['consentee:list'],
        features: [CONSENTEE_FEATURE],
      }),
    mixPanelName: 'Menu link clicked consentees',
  },
  {
    key: 'STUDY_OPTIONS',
    icon: <SettingsIcon color="inherit" />,
    title: <FormattedMessage defaultMessage="Study settings" />,
    path: `/admin/studies/:studyId`,
    userOnboardingId: 'menu-settings',
    accessRequirements: checkAccess =>
      checkAccess({ actions: ['study:update'] }),
    mixPanelName: 'Menu link clicked study settings',
  },
];

export default menuItems;
