import { gql, useQuery } from '@apollo/client';
import { useAuth, useCheckAccess } from '@lib/authentication';
import { isEmpty } from '@lib/fp';
import { useFreshDeskDisplayStyle } from '@lib/hooks';
import { getFeatureAttributes } from '@lib/studies/getFeatureAttributes';
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import FreshDesk from 'src/components/FreshDesk';
import LayoutHeader from '../AppHeader';
import Navigation from './Navigation';
import menuItems from './navigationData';

const QUERY = gql`
  query WEB_ActiveStudiesForLayout {
    studies(filter: { status: ACTIVE, mustHaveActiveFeatures: true }) {
      nodes {
        id
        internalStudyName
        featureOptions {
          studyId
          recruitmentPortal
          consenteesPortal
          analyticsPortal
        }
      }
    }
  }
`;

const beforeQuery = () => ({
  fetchPolicy: 'cache-and-network',
});

const afterQuery = data => ({
  studies: (data?.studies?.nodes ?? []).map(s => ({
    ...s,
    enabledFeature: getEnabledFeature(s),
  })),
});

const getEnabledFeature = s =>
  Object.entries(s.featureOptions).find(([k, v]) => v === true)[0];

function StudyLayout({
  children,
  navActions = null,
  scrollContent = false,
  showDivider = false,
}) {
  const router = useRouter();
  const { user, roles } = useAuth();
  const { studyId } = router.query;
  const { checkAccess } = useCheckAccess(studyId);
  const { data } = useQuery(QUERY, beforeQuery());
  const { studies } = afterQuery(data);

  useMustCompleteLearning();
  useFreshDeskDisplayStyle();

  const { key: feature } =
    menuItems.find(({ key }) => router.asPath.includes(key)) ?? {};

  useEffect(() => {
    if (!user.id) return;

    window.stonlyTrack?.('identify', user.id, {
      'disable-release-popup': user.email.includes('trialbee.test'),
      'user-role': roles[0],
    });
  }, [user]);

  const getContent = () => {
    if (isEmpty(studies)) {
      return <NoStudies />;
    }

    const allowAccess = menuItems
      .find(({ key }) => router.asPath.includes(key))
      ?.accessRequirements?.(checkAccess);

    if (feature && !allowAccess) {
      return <NotAllowedAccess feature={feature} />;
    } else {
      return children;
    }
  };

  return (
    <Box sx={{ background: theme => theme.background.standard }}>
      <FreshDesk />
      <AppBar
        elevation={0}
        position="relative"
        color="transparent"
        square
        sx={{ pl: 9 }}
      >
        <Toolbar
          disableGutters
          variant="dense"
          aria-label="main menu"
          sx={{
            boxShadow: ({ palette }) =>
              showDivider && `0px -1px 0px ${palette.grey[300]} inset`,
          }}
        >
          {navActions}

          <span style={{ flex: 1 }} />

          <LayoutHeader studyId={studyId} />
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          maxWidth: '100%',
          overflowX: 'hidden',
          overflowY: scrollContent ? 'auto' : 'hidden',
          background: 'transparent',
          height: `calc(100vh - 48px)`,
          pt: 1,
          pb: 3,
          pr: 3,
          pl: 12,
        }}
      >
        {getContent()}
      </Box>

      <Navigation studies={studies} feature={feature} studyId={studyId} />
    </Box>
  );
}

const useMustCompleteLearning = () => {
  const { checkAccess, loading } = useCheckAccess();
  const { user } = useAuth();
  const router = useRouter();

  const consented = user?.learning?.consented ?? false;
  const mustLearn = checkAccess({ actions: ['user.learning.resources:list'] });

  if (loading) {
    return;
  }

  if (!consented && mustLearn) {
    router.push('/learning');
  }
};

const NotAllowedAccess = ({ feature }) => {
  const intl = useIntl();

  const { name, Icon } = getFeatureAttributes(feature);
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%' }}
      spacing={2}
    >
      <Icon sx={{ fontSize: 150, color: 'grey.300' }} />

      <Typography variant="h5" component="h1" color="grey.700">
        {intl.formatMessage(
          {
            defaultMessage:
              'The {feature} module is not activated in this study',
          },
          { feature: name },
        )}
      </Typography>

      <Typography variant="body1" color="grey.700">
        {intl.formatMessage({
          defaultMessage: 'Select another module in the menu to the left.',
        })}
      </Typography>
    </Stack>
  );
};

const NoStudies = () => {
  const intl = useIntl();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%' }}
      spacing={2}
    >
      <Typography variant="h3" component="h1" color="primary">
        {intl.formatMessage({
          defaultMessage: "You don't have access to any studies",
        })}
      </Typography>

      <Typography variant="subtitle1" color="primary">
        {intl.formatMessage({
          defaultMessage: 'Contact your administrator to gain access',
        })}
      </Typography>
    </Stack>
  );
};

export default StudyLayout;
