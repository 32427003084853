import { useCheckAccess } from '@lib/authentication';
import { useHover, usePersistedState } from '@lib/hooks';
import { useTracking } from '@lib/tracking';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
  alpha,
  Box,
  Drawer,
  Grow,
  List,
  Slide,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'src/components/LinkNext';
import menuItems from './navigationData';
import StudyDropdown from './StudyDropdown';
import SubNavigation from './SubNavigation';

const expandedDrawerWidth = 240;
const collapsedDrawerWidth = 72;

/**
 * @param {object} props
 * @param {[object]} props.studies
 * @param {string} props.studyId
 * @param {string} props.feature
 */
const Navigation = ({ studies, studyId, feature }) => {
  const theme = useTheme();
  const { checkAccess } = useCheckAccess(studyId);
  const { track, track_links } = useTracking();

  const [hoverRef, isHovered] = useHover();
  const [isPinned, setIsPinned] = usePersistedState({
    defaultValue: false,
    key: '__navigation_pinned_collapsed__',
  });
  // This state is not controlling the dropdown, it is just communicated back so
  // the sidebar can be prevented from collapsing while the dropdown is open.
  const [isStudyDropdownOpen, setIsStudyDropdownOpen] = useState(false);

  const isExpanded = !isPinned && (isHovered || isStudyDropdownOpen);
  const drawerWidth = isExpanded ? expandedDrawerWidth : collapsedDrawerWidth;

  const authorizedSubList = lists =>
    Object.entries(lists).filter(([, entry]) =>
      checkAccess(entry.accessRequirements),
    );

  const authorizedList = studyId
    ? menuItems
        .filter(menuItem => menuItem.accessRequirements(checkAccess))
        .map(item => ({
          ...item,
          items: item.list ? authorizedSubList(item.list) : [],
        }))
    : [];

  useEffect(() => {
    if (studyId) {
      authorizedList.forEach(({ userOnboardingId, mixPanelName }) => {
        track_links(`[data-testid="${userOnboardingId}"]`, mixPanelName);
      });
    }
  });

  const dropdownDidToggle = isOpen => {
    if (isOpen) {
      track('Menu select clicked study selector');
    }

    setIsStudyDropdownOpen(isOpen);
  };

  const handleTogglePinCollapsed = () => setIsPinned(pinned => !pinned);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        pt: 2,
        px: 1,
        pb: 3,
      }}
    >
      <Box>
        <Link href="/studies">
          <Box
            sx={{
              height: 50,
              mt: 1,
              mb: 6,
              px: isExpanded ? 2 : 1,
              transition: studyDropdownPaddingTransition(isExpanded),
            }}
          >
            <Slide direction="left" in={isExpanded}>
              <Box position="absolute" ml={1} width={160} height={50}>
                <Image
                  src={theme.logo.navigationExpanded}
                  alt="Trialbee logo"
                  width={160}
                  height={50}
                />
              </Box>
            </Slide>
            <Slide direction="right" in={!isExpanded}>
              <Box position="absolute" width={40} height={40}>
                <Image
                  src={theme.logo.navigationCollapsed}
                  alt="Trialbee logo"
                  width={40}
                  height={40}
                />
              </Box>
            </Slide>
          </Box>
        </Link>

        <Box
          sx={{
            px: isExpanded ? 1 : 0,
            transition: studyDropdownPaddingTransition(isExpanded),
          }}
        >
          <StudyDropdown
            studyId={studyId}
            studies={studies}
            feature={feature}
            isExpanded={isExpanded}
            dropdownDidToggle={dropdownDidToggle}
          />
        </Box>

        <Box mt={1} px={1}>
          <List>
            {authorizedList.map(
              ({ key, title, icon, path, items, userOnboardingId }) => (
                <SubNavigation
                  key={key}
                  title={title}
                  icon={icon}
                  items={items}
                  path={path.replace('/:studyId', `/${studyId}`)}
                  active={feature === String(key)}
                  userOnboardingId={userOnboardingId}
                  expanded={isExpanded}
                />
              ),
            )}
          </List>
        </Box>
      </Box>
      <PinCollapseButton
        expanded={isExpanded}
        pinned={isPinned}
        onClick={handleTogglePinCollapsed}
      />
    </Box>
  );

  return (
    <Drawer
      ref={hoverRef}
      variant="permanent"
      sx={{
        position: 'relative',
        minWidth: drawerWidth, // This sets the min width for the sidebar
        flexShrink: 0,
        zIndex: 5,
        '& .MuiDrawer-paper': {
          background: ({ background }) => background.navigation,
          border: 'none',
          width: drawerWidth,
          overflow: 'hidden',
          transition: drawerWidthTransition(isExpanded),
        },
      }}
    >
      {content}
    </Drawer>
  );
};

const drawerWidthTransition =
  isExpanded =>
  ({ transitions }) =>
    transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: isExpanded
        ? transitions.duration.enteringScreen
        : transitions.duration.leavingScreen,
    });

const studyDropdownPaddingTransition =
  isExpanded =>
  ({ transitions }) =>
    transitions.create('padding', {
      easing: transitions.easing.sharp,
      duration: isExpanded
        ? transitions.duration.enteringScreen
        : transitions.duration.leavingScreen,
    });

const PinCollapseButton = ({ pinned, expanded, onClick }) => {
  const intl = useIntl();

  return (
    <Stack
      direction="row"
      spacing={1}
      onClick={onClick}
      sx={{
        mt: 3,
        mx: 2,
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        borderRadius: 1,
        color: ({ palette }) => alpha(palette.common.white, 0.75),
        '&:hover': {
          background: ({ palette }) => alpha(palette.common.white, 0.08),
        },
      }}
    >
      <KeyboardDoubleArrowLeftIcon
        sx={{
          transform: `rotate(${Number(pinned) * 180}deg)`,
          transition: 'transform 150ms ease-out',
        }}
      />
      <Grow
        in={expanded}
        style={{ transformOrigin: 'left center' }}
        {...(expanded ? { timeout: 700 } : {})}
      >
        <Typography variant="subtitle1" noWrap>
          {intl.formatMessage({ defaultMessage: 'Collapse menu' })}
        </Typography>
      </Grow>
    </Stack>
  );
};

export default Navigation;
